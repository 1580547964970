/* You can add global styles to this file, and also import other style files */
:root {
  --win-width:320;
  --win-height:454;
}
/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
//@import "themes";
//@import '~highlight.js/scss/github.scss';
html {
  font-size: 18px;
}


html, body {
  --border-size: 2px;
  height:  var(--win-height);
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  max-height: var(--win-height);
}

body *:not(mat-icon) {
  margin: 0;
  font-family: ElectroluxSans, "Helvetica Neue", sans-serif !important;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #011e41
}


::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.5rem;
}

::-webkit-scrollbar:hover {
  width: 1rem;
  height: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #011e41b0;
  border-radius: 0.3rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #011e41b0;
}


.elx-blue {
  color: #011e41;
}

.page-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
}

.spacer {
  flex: 1 1 auto;
}

.la-square-loader > div:after {
  background-color: white;
}

.la-square-loader > div {
  border-color: white;
}

.alert-container {
  position: absolute;
  top: 2px;
  left: 3px;
  right: 3px;
}


.form-control {
  width: initial;
}

.form-control:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 0 0.2rem var(--accent-lighter-color);

}

.right {
  float: right;
}

.angular-editor-textarea {
  overflow-x: auto !important;
}

.status-text-error {
  color: rgb(207, 0, 0);
}

.full-width {
  width: 100%;
}

.backdropBackground {
  backdrop-filter: blur(10px);
}

.cst-broder {
  border-style: dashed;
  border-width: 2px;
  border-radius: 10px;
  border-color: var(--primary-color);
}

.pointer {
  cursor: pointer;
  color: var(--primary-color);
}

.disabled {
  pointer-events: none;
}

:host(.disabled) {
  pointer-events: none;
}

mat-icon {
  font-size: 32px !important;
  height: 32px !important;
  width: 32px !important;
}

button:has(mat-icon) {
  height: 32px !important;
  min-width: 32px !important;
}

.no-selectable-item {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cdk-overlay-pane.my-dialog {
  position: relative !important;
  padding: 6px !important;
}

.close.mat-icon-button {
  position: absolute;
  top: 2px;
  right: 2px;
}

.max-width {
  flex: 1 1 auto !important;
  width: 100% !important;
}

.h1-title{
  font-size: 1.5rem !important;
  font-weight: 800 !important;
}

.h2-title{
  font-size: 1.3rem !important;
  font-weight: 800 !important;
}

.h3-title{
  font-size: 1.2rem !important;
  font-weight: 800 !important;
}

@media (max-height: 600px) {
  #ddt-logo{
    display: none;
  }
}

.rotate-phone{
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  z-index: 99999;
  background-color: var(--accent-lighter-color);

  .h1-title{
    font-size: calc(1rem + 3vw) !important;
    font-weight: 800 !important;
  }
}

@media screen and (orientation:landscape) {
  .rotate-phone{
    display: flex;
    flex-direction: column;
  }
}

.mat-menu-content{
  min-width: max-content !important;
  margin: auto !important;
}

custom-btn{
  display: flex;
  flex-direction: row;
  flex: 1;
}

app-scorecard{
  overflow-x: hidden;
}

app-home{
  width: 100%;
  height: 100%;
}

not-found{
  width: 100%;
  height: 100%;
}

.mat-button-wrapper > label {
  vertical-align: baseline !important;
}

.mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-30 {
  height: 30%;
}

.mat-dialog-parent > mat-dialog-container.mat-dialog-container {
  border-radius: revert !important;
}

.fs-7{
  font-size: 0.75rem !important;
}

.fs-8{
  font-size: 0.5rem !important;
}

.body-content{
  display: flex;
  flex-direction: column;
  //width: 100%;
  width: 100vw;
  align-items: flex-start;
}
